import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { Article, OrangeLayout } from '../components'
import config from '../../config'

const Category = ({ pageContext: { category }, data: { allMdx } }) => {
  const { nodes, totalCount } = allMdx
  const subline = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${category}"`
  const fullbyline = <>{subline} (See <Link to="/blog/categories">all categories</Link>)</>

  return <OrangeLayout
      title={`Category: ${category}`}
      byline={fullbyline}
    >
    <Helmet title={`Category: ${category} | ${config.siteTitle}`} />
    {nodes.map(post => (
      <Article
        title={post.frontmatter.title}
        date={post.fields.date}
        excerpt={post.excerpt}
        timeToRead={post.timeToRead}
        slug={post.fields.slug}
        categories={post.frontmatter.categories}
        key={post.fields.slug}
      />
    ))}
  </OrangeLayout>;
}

export default Category

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export const postQuery = graphql`
  query CategoryPage($category: String!) {
    allMdx(
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: $category } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          categories
        }
        fields {
          slug
          date(formatString: "MM/DD/YYYY")
        }
        excerpt(pruneLength: 200)
        timeToRead
      }
    }
  }
`
